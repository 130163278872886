const salonModule = useSalon();
const landingModule = useLanding();

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path == import.meta.env.VITE_CRM_PATH) {
    return;
  }
  try {
    let result = false;
    if (landingModule.landing.slug !== to.params.id && salonModule.salon.slug !== to.params.id) {
      result = await salonModule.loadSalon(to.params.id);
    } else {
      return;
    }

    if (!result) {
      if (landingModule.landing.slug !== to.params.id) {
        result = await landingModule.loadLanding(to.params.id);
      } else {
        return;
      }
    }

    if (!result) {
      return navigateTo("/not-found");
    }
  } catch (err) {
    console.log("check-salon-landing err", err);
  }
});
